import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { updateDetails } from '../redux/actions/roleActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Dropdown from '../components/Common/Dropdown.js';
import dayjs from 'dayjs';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from 'reactstrap';

import { useModal } from '../zustand/modalStore.js';

// core components
import client from '../feathers';
import ModalWithCallback from '../components/Common/ModalWithCallback.js';

const validationSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .required('First Name is required'),
    // lastName: Yup.string()
    //   .required('Last Name is required'),
    // gender: Yup.string()
    //   .required('Gender is required'),
    // birth: Yup.string()
    //   .required('Birth is required'),
    // address: Yup.string()
    //   .required('Address is required'),
    // state: Yup.string()
    //   .required('State is required'),
    // country: Yup.string()
    //   .required('Country is required'),
    // postalCode: Yup.string()
    //   .required('PostalCode is required'),
    // phoneNumber: Yup.string()
    //   .required('Contact No. is required'),
});

const Profile = (props) => {
    const [ message, setMessage ] = useState('');
    const [ color, setColor ] = useState('');
    const [ isOpen, setIsOpen ] = useState(false);
    const [ productCount, setProductCount ] = useState({});
    const [ componentCount, setComponentCount ] = useState({});
    const [ profitMarginCount, setProfitMarginCount ] = useState(0);
    const [ dynamicPriceCount, setDynamicPriceCount ] = useState(0);
    const [ totalApiCalls, setTotalApiCalls ] = useState(0);

    const notificationOpen = (bool, color, message) => {
        setIsOpen(bool);
        setColor(color);
        setMessage(message);
    };


    const {
        isOpen: modalIsOpen,
        title: modalTitle,
        content: modalContent,
        callback: modalCallback,

        actions: modalActions,
    } = useModal();

    const onSubmit = async(value) => {
        if(props.userInfo._id){
            client.authenticate()
                .then(()=>{
                    return client.service('users').patch(props.userInfo._id, value);
                })
                .then((res) => {
                    props.updateDetails(res);
                    modalActions.setIsOpen(true);
                    modalActions.setTitle('User Updated');
                    modalActions.setContent(`User ${res.username} Updated Successfully!`);
                    modalActions.setCallback(undefined);
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('');
                        modalActions.setContent('Please Sign-in to continue!');
                        modalActions.setCallback(undefined);
                    }else{
                        modalActions.setIsOpen(true);
                        modalActions.setTitle('Something went wrong!');
                        modalActions.setContent(err.message);
                        modalActions.setCallback(undefined);
                    }
                });
        }
    };

    useEffect(() => {
        if(props.userInfo._id){
            client.authenticate()
                .then(()=>{
                    return client.service('products').find({
                        query: {
                            userId: props.userInfo._id,
                            $limit: 1000000
                        }
                    });
                })
                .then((res) => {
                    let apiCount = 0;
                    let csvCount = 0;
                    let bookletCount = 0;
                    let looseCount = 0;
                    let posterCount = 0;

                    res.data.map(product => {
                        if(product.source === 'api') apiCount++;
                        if(product.source === 'csv') csvCount++;
                        if(product.source === 'booklet') bookletCount++;
                        if(product.source === 'loose-sheet-v2') looseCount++;
                        if(product.source === 'poster') posterCount++;
                    });
                    setProductCount({
                        api: apiCount,
                        csv: csvCount,
                        booklet: bookletCount,
                        looseSheet: looseCount,
                        poster: posterCount,
                        total: res.data.length,
                    });
                })
                .then(()=>{
                    return client.service('components').find({
                        query: {
                            userId: props.userInfo._id,
                            $limit: 1000000
                        }
                    });
                })
                .then((res) => {
                    let materialCount = 0;
                    let sizeCount = 0;
                    let costCount = 0;
                    let quantityCount = 0;
                    let artworkCount = 0;
                    let productionCount = 0;
                    let bindingCount = 0;
                    let addComponentCount = 0;
                    let fileStorageCount = 0;

                    res.data.map(product => {
                        if(product.componentTypeName === 'Material') materialCount++;
                        else if(product.componentTypeName === 'Printing Size') sizeCount++;
                        else if(product.componentTypeName === 'Printing Cost') costCount++;
                        else if(product.componentTypeName === 'Quantity') quantityCount++;
                        else if(product.componentTypeName === 'Artwork Service') artworkCount++;
                        else if(product.componentTypeName === 'Production Timeline') productionCount++;
                        else if(product.componentTypeName === 'Binding Type') bindingCount++;
                        else if(product.componentTypeName === 'File Storage') fileStorageCount++;
                        else addComponentCount++;
                    });
                    setComponentCount({
                        material: materialCount,
                        size: sizeCount,
                        cost: costCount,
                        quantity: quantityCount,
                        artwork: artworkCount,
                        production: productionCount,
                        binding: bindingCount,
                        addComp: addComponentCount,
                        file: fileStorageCount,
                    });
                })
                .then(()=>{
                    return client.service('dynamicPrice').find({
                        query: {
                            userId: props.userInfo._id,
                            $limit: 1
                        }
                    });
                })
                .then((res) => {
                    setProfitMarginCount(res.total);
                })
                .then(()=>{
                    return client.service('margins').find({
                        query: {
                            userId: props.userInfo._id,
                            $limit: 1
                        }
                    });
                })
                .then((res) => {
                    setDynamicPriceCount(res.total);
                })
                .then(()=>{
                    return client.service('apiCalls').find({
                        query: {
                            userId: props.userInfo._id,
                            apiCallType: 'changeProductSpecs',
                            $limit: 1
                        }
                    });
                })
                .then((res) => {
                    setTotalApiCalls(res.total);
                });
        }
    }, []);

    return (
        <>
            {/* eslint-disable-next-line no-undef */}
            {(window.self === window.top) && (
                <div className='header' style={{height: 64}}>
                    <span className='mask' style={{backgroundColor: '#fff'}} />
                </div>
            )}
            {/* Page content */}
            <Container className='mt-4' fluid>
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName: props.userInfo.firstName?props.userInfo.firstName:'',
                        lastName: props.userInfo.lastName?props.userInfo.lastName:'',
                        wablas_key: props.userInfo.wablas_key || '',
                        wablas_domain: props.userInfo.wablas_domain || '',
                        companyName: props.userInfo.companyName || '',
                        phoneNumber: props.userInfo.phoneNumber || '',
                        gender: props.userInfo.gender?props.userInfo.gender:'',
                        birth: props.userInfo.birth?props.userInfo.birth:'',
                        address: props.userInfo.address?props.userInfo.address:'',
                        country: props.userInfo.country?props.userInfo.country:'',
                        countryCode: props.userInfo.countryCode?props.userInfo.countryCode:'',
                        stateOrProvinceCode: props.userInfo.stateOrProvinceCode?props.userInfo.stateOrProvinceCode:'',
                        state: props.userInfo.state?props.userInfo.state:'',
                        postalCode: props.userInfo.postalCode?props.userInfo.postalCode:'',
                        description: props.userInfo.description?props.userInfo.description:'',
                        package: props.userInfo.package?props.userInfo.package:undefined,
                        startDate: props.userInfo.startDate? dayjs(props.userInfo.startDate).format('YYYY-MM-DD') :'',
                        endDate: props.userInfo.endDate? dayjs(props.userInfo.endDate).format('YYYY-MM-DD'):'',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            handleChange,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <Card>
                                    <CardHeader>
                                        <Row className='align-items-center'>
                                            <Col xs='8'>
                                                <h2 className='mb-0'>Profile Setting</h2>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody className='bg-product rounded'>
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='input-username'
                                                    >
                                                        Username
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        defaultValue={props.userInfo.username?props.userInfo.username:''}
                                                        placeholder='Username'
                                                        type='text'
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='input-email'
                                                    >
                                                        Email address
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        defaultValue={props.userInfo.email?props.userInfo.email:''}
                                                        placeholder='jesse@example.com'
                                                        type='email'
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='3'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='firstName'
                                                    >
                                                        First name
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        id='firstName'
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        placeholder='First name'
                                                        type='text'
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg='3'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='lastName'
                                                    >
                                                        Last name
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        id='lastName'
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        placeholder='Last name'
                                                        type='text'
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='3'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='phoneNumber'
                                                    >
                                                        Contact Number
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        id='phoneNumber'
                                                        value={values.phoneNumber}
                                                        onChange={handleChange}
                                                        placeholder='Contact'
                                                        type='text'
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='3'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='companyName'
                                                    >
                                                        Company Name
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        id='companyName'
                                                        value={values.companyName}
                                                        onChange={handleChange}
                                                        placeholder='Company Name'
                                                        type='text'
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {(props.role === 'admin') && (
                                    <>
                                        <Row className='mt-4'>
                                            <Col md='3'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='startDate'
                                                    >
                                                        Start date
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        type='date'
                                                        id='startDate'
                                                        value={values.startDate}
                                                        onChange={handleChange}
                                                        placeholder='Start Date'
                                                        disabled
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md='3'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='endDate'
                                                    >
                                                        End date
                                                    </label>
                                                    <Input
                                                        className='form-control-alternative'
                                                        type='date'
                                                        id='endDate'
                                                        value={values.endDate}
                                                        onChange={handleChange}
                                                        placeholder='End Date'
                                                        disabled
                                                        onWheel={ event => event.currentTarget.blur() }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {(!!props.userInfo.package) && (
                                            <Row>
                                                <Col md='3'>
                                                    <FormGroup>
                                                        <label
                                                            className='form-control-label'
                                                            htmlFor='package'
                                                        >
                                                            Package
                                                        </label>
                                                        <Dropdown
                                                            name={'package'}
                                                            disabled
                                                            values={props.packages.map((x) => {
                                                                return {
                                                                    value: x._id,
                                                                    display: x.name
                                                                };
                                                            })}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                )}
                                <Row className='mt-4'>
                                    <Col lg='6'>
                                        <FormGroup>
                                            <label
                                                className='form-control-label'
                                                htmlFor='input-id'
                                            >
                                                API Key
                                            </label>
                                            <Input
                                                className='form-control-alternative'
                                                defaultValue={props.userInfo._id || ''}
                                                placeholder='API Key'
                                                type='text'
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {(!props.packageDetails?.customWhatsapp) && (
                                    <>
                                        <Row>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='wablas_key'
                                                    >
                                                        Custom WhatsApp API (wablas)
                                                    </label>
                                                    <div className='d-flex align-items-center'>
                                                        <Input
                                                            className='form-control-alternative mr-2'
                                                            id='wablas_key'
                                                            value={values.wablas_key}
                                                            onChange={handleChange}
                                                            placeholder='eg: YTFGUHYUFGYUHIUHT^&TGUHUIHFTDTRDFYFYUGUGUGUGGIG'
                                                            type='text'
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <label
                                                        className='form-control-label'
                                                        htmlFor='wablas_domain'
                                                    >
                                                        WhatsApp Domain Url (wablas)
                                                    </label>
                                                    <div className='d-flex align-items-center'>
                                                        <Input
                                                            className='form-control-alternative mr-2'
                                                            id='wablas_domain'
                                                            value={values.wablas_domain}
                                                            onChange={handleChange}
                                                            placeholder='eg: https://kudus.wablas.com'
                                                            type='text'
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                    
                                )}
                                
                                {((!!props.userInfo.package) && props.role === 'admin') && (
                                    <>
                                        <Col md='6' className='mb-3'>
                                            <Row>
                                                <h2 className='mt-4'>Usage Statistics</h2>
                                            </Row>
                                            <Row>
                                                <label className='form-control-label text-underline color-black'>Products</label>
                                            </Row>
                                            <Row>
                                                <Col md='4' className='mr-5'>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Group (API)</small>
                                                        <small className='form-control-label color-black'>{productCount.api ?? '-'} of {props.packageDetails.productRestrictions.api}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>CSV</small>
                                                        <small className='form-control-label color-black'>{productCount.csv ?? '-'} of {props.packageDetails.productRestrictions.csv}</small>
                                                    </Row><Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>SC-Booklet</small>
                                                        <small className='form-control-label color-black'>{productCount.booklet ?? '-'} of {props.packageDetails.productRestrictions.booklet}</small>
                                                    </Row>
                                                </Col>
                                                <Col md='4'>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>SC-Loose Sheet</small>
                                                        <small className='form-control-label color-black'>{productCount.looseSheet ?? '-'} of {props.packageDetails.productRestrictions.looseSheet}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>SC-Poster</small>
                                                        <small className='form-control-label color-black'>{productCount.poster ?? '-'} of {props.packageDetails.productRestrictions.poster}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Maximum Products</small>
                                                        <small className='form-control-label color-black'>{productCount.total ?? '-'} of {props.packageDetails.productRestrictions.total}</small>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md='6' className='mb-3'>
                                            <Row>
                                                <label className='form-control-label text-underline color-black'>Components</label>
                                            </Row>
                                            <Row>
                                                <Col md='4' className='mr-5'>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Material</small>
                                                        <small className='form-control-label color-black'>{componentCount.material ?? '-'} of {props.packageDetails.componentRestrictions.material}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Printing Size</small>
                                                        <small className='form-control-label color-black'>{componentCount.size ?? '-'} of {props.packageDetails.componentRestrictions.printingSize}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Printing Cost</small>
                                                        <small className='form-control-label color-black'>{componentCount.cost ?? '-'} of {props.packageDetails.componentRestrictions.printingCost}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Quantity</small>
                                                        <small className='form-control-label color-black'>{componentCount.quantity ?? '-'} of {props.packageDetails.componentRestrictions.quantity}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Artwork Services</small>
                                                        <small className='form-control-label color-black'>{componentCount.artwork ?? '-'} of {props.packageDetails.componentRestrictions.artwork}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Production Timeline</small>
                                                        <small className='form-control-label color-black'>{componentCount.production ?? '-'} of {props.packageDetails.componentRestrictions.productionTimeline}</small>
                                                    </Row>
                                                </Col>
                                                <Col md='4'>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Binding Type</small>
                                                        <small className='form-control-label color-black'>{componentCount.binding ?? '-'} of {props.packageDetails.componentRestrictions.bindingType}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Additional Component</small>
                                                        <small className='form-control-label color-black'>{componentCount.addComp ?? '-'} of {props.packageDetails.componentRestrictions.additionalComponent}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Profit Margin</small>
                                                        <small className='form-control-label color-black'>{profitMarginCount ?? '-'} of {props.packageDetails.componentRestrictions.profitMargin}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Dynamic Pricing</small>
                                                        <small className='form-control-label color-black'>{dynamicPriceCount ?? '-'} of {props.packageDetails.componentRestrictions.dynamicPrice}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>File Storage</small>
                                                        <small className='form-control-label color-black'>{componentCount.file ?? '-'} of {props.packageDetails.componentRestrictions.fileStorage}</small>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md='6' className='mb-3'>
                                            <Row>
                                                <label className='form-control-label text-underline color-black'>Modules</label>
                                            </Row>
                                            <Row>
                                                <Col md='4' className='mr-5'>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Cost Breakdown</small>
                                                        <small className='form-control-label color-black'>{props.packageDetails.moduleRestrictions.costBreakdown ? 'Restricted' : 'Enabled'}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Calculation Limit</small>
                                                        <small className='form-control-label color-black'>{props.packageDetails.moduleRestrictions.calculation.enabled ? 'Restricted' : 'Enabled'}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Template Link</small>
                                                        <small className='form-control-label color-black'>{props.packageDetails.moduleRestrictions.templateLink ? 'Restricted' : 'Enabled'}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>Custom WhatsApp</small>
                                                        <small className='form-control-label color-black'>{props.packageDetails.moduleRestrictions.customWhatsapp ? 'Restricted' : 'Enabled'}</small>
                                                    </Row>
                                                </Col>
                                                <Col md='4' className='mr-5'>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>-</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small className='form-control-label color-black'>{props.packageDetails.moduleRestrictions.calculation.enabled ? `${totalApiCalls} of ${props.packageDetails.moduleRestrictions.calculation.limit}` : ''}</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>-</small>
                                                    </Row>
                                                    <Row className='d-flex align-items-center justify-content-between mb-2'>
                                                        <small>-</small>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                
                                

                                <div className='modal-footer'>
                                    <Button
                                        color='primary' 
                                        type='submit'
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <Row>
                </Row>
            </Container>
            <ModalWithCallback
                isOpen={modalIsOpen}
                toggle={() => modalActions.toggle()}
                title={modalTitle}
                content={modalContent}
                callback={modalCallback}
            />
        </>
    );
};


const mapStateToProps = state => ({
    role: state.role.details.user?state.role.details.user.role:'',
    auth: state.role.auth,
    userId: state.role.details.user?state.role.details.user._id:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    packages: state.allPackages.data,
    packageDetails: state.packageDetails.data,
});

const mapDispatchToProps = {
    updateDetails: updateDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);