import React from 'react';
import { Field, FieldArray } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

// reactstrap components
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Card,
    CardBody,
    Row,
    Col,
    // Table
} from 'reactstrap';

const DynamicPriceCalc = ({
    dynamicPriceBetween,
    dynamicPriceLastCondition,
    setDynamicPriceBetween,
    dynamicPriceBetweenOnChange,
    removeDynamicPriceBetween,

    dynamicPriceData,
    setDynamicPriceLastCondition,

    values,
    setFieldValue,
}) => {
    const applyDynamicPrice = (value) => {
        if(value.dynamicPriceBetween.length > 0) {
            setDynamicPriceBetween([...value.dynamicPriceBetween]);
            setFieldValue('type', 'dynamicPrice');
        } else if(value.sqftBetween.length > 0) {
            setFieldValue('sqftBetween', value.sqftBetween);
            setFieldValue('type', 'sqft');
        }
    };

    const addCondition = (type) => {
        if(type === 'sqft') {
            // handled by formik
        } else {
            if(dynamicPriceBetween[dynamicPriceBetween.length -1]){
                setDynamicPriceBetween(dynamicPriceBetween.concat({
                    quantity: dynamicPriceBetween[dynamicPriceBetween.length -1]?.quantity + 1,
                    percent: 0
                }));
            }else{
                setDynamicPriceBetween(dynamicPriceBetween.concat({
                    quantity: 0,
                    percent: 0
                }).concat({
                    quantity: 1,
                    percent: 0,
                    type: 0
                }));
            }
        }
    };

    const addSqftBetween = (push) => {
        const cloned = cloneDeep(values.sqftBetween);
        cloned.sort((a, b) => a.minSqft - b.minSqft);
        setFieldValue('sqftBetween', cloned);
        if(values.sqftBetween.length <= 0) {
            push({
                minSqft: 0,
                percent: 0
            });
            push({
                minSqft: 0.0001,
                percent: 0
            });
        } else {
            const highest = values.sqftBetween.reduce((acc, value) => {
                return (acc = acc > value.minSqft ? acc : value.minSqft);
            }, 0);
            
            push({
                minSqft: highest + 0.0001,
                percent: 0
            });
        }
    };

    const lastTwoInput = (e, i) => {
        let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);

        cloneDynamicPriceBetween[i]['quantity'] = Number(e.target.value);

        if(dynamicPriceBetween.length -2 === i){
            if(e.target.value){
                cloneDynamicPriceBetween[i+1]['quantity'] = parseFloat(e.target.value) + 1; 
            }else{
                cloneDynamicPriceBetween[i+1]['quantity'] = 1;
            }
        }

    
        setDynamicPriceBetween(cloneDynamicPriceBetween);
    };

    const performQuantityCheck = (event, i) => {
        if(i > 0 && event.target.value < dynamicPriceBetween[i-1].quantity) {
            let cloneDynamicPriceBetween = cloneDeep(dynamicPriceBetween);

            cloneDynamicPriceBetween[i].quantity = cloneDynamicPriceBetween[i-1].quantity + 1;
            setDynamicPriceBetween(cloneDynamicPriceBetween);
        }
    };


    const lastTwoInputSqft = (e, i) => {
        let cloned = cloneDeep(values.sqftBetween);

        const highest = cloned.reduce((acc, value, index) => {
            if (cloned.length - 1 === index) return acc;
            return (acc = acc > value.minSqft ? acc : value.minSqft);
        }, 0);

        cloned[cloned.length - 1]['minSqft'] = highest + 0.0001;
        setFieldValue('sqftBetween', cloned);
    };

    return (  
        <>
            <Card className='border'>
                <CardBody className='p-0'>
                    <Row className='px-4 py-3 m-0 d-flex align-items-center'>
                        <h4 className='m-0 mr-4'>Dynamic Price Rules</h4>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle caret color='secondary' className='text-uppercase'>
                                Clone Dynamic Price
                            </DropdownToggle>
                            <DropdownMenu className='w-100'>
                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                    {
                                        dynamicPriceData.map((x, i) => {
                                            return (
                                                <DropdownItem
                                                    key={i} 
                                                    className='text-capitalize' 
                                                    value={x.dynamicPriceName} 
                                                    onClick={() => {
                                                        applyDynamicPrice(x);
                                                    }}
                                                >
                                                    {x.dynamicPriceName}
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle caret color='secondary' className='text-uppercase'>
                                {values?.type}
                            </DropdownToggle>
                            <DropdownMenu className='w-100'>
                                <div className='editInputs-colors-scrollbar mac-scrollbar'>
                                    <DropdownItem
                                        className='text-capitalize' 
                                        value='quantity' 
                                        onClick={() => {
                                            setFieldValue('type', 'quantity');
                                        }}
                                    >
                                        Quantity
                                    </DropdownItem>
                                    <DropdownItem
                                        className='text-capitalize' 
                                        value='sqft' 
                                        onClick={() => {
                                            setFieldValue('type', 'sqft');
                                        }}
                                    >
                                        Sqft
                                    </DropdownItem>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Row>
                    
                    <div className='p-4 bg-light-grey'>
                        {values.type === 'sqft' ? (
                            <FieldArray
                                className='mb-3'
                                name='sqftBetween'
                                render={({ push, remove }) => (
                                    <>
                                        <Row className='mb-2'>
                                            <Col style={{ maxWidth: 100 }}>
                                            </Col>
                                            <Col style={{ maxWidth: 140 }}>
                                                <small className='font-weight-bold'>
                                                    Max Sqft
                                                </small>
                                            </Col>
                                            <Col style={{ maxWidth: 170 }}>
                                            </Col>
                                            <Col style={{ maxWidth: 200 }}>
                                                <small className='font-weight-bold'>
                                                    Discount from base cost
                                                </small>
                                            </Col>
                                            <Col style={{ maxWidth: 140 }}>
                                            </Col>
                                        </Row>
                                        {values.sqftBetween.map((x, i) => {
                                            return (
                                                <Row key={i} className='mb-2'>
                                                    <Col style={{ maxWidth: 100 }}>
                                                        <p className='text-sm font-weight-normal mb-0'>{'If Sqft is'}</p>
                                                    </Col>
                                                    <Col style={{ maxWidth: 140 }}>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            type='number'
                                                            onWheel={(e) => e.target.blur()}
                                                            name={`sqftBetween[${i}].minSqft`}
                                                            disabled={(i === values.sqftBetween.length - 1)}
                                                            onBlur={(e) => lastTwoInputSqft(e, i)}
                                                        />
                                                    </Col>
                                                    {(i === values.sqftBetween.length - 1) ? (
                                                        <Col style={{ maxWidth: 170 }} className=''>
                                                            <p className='text-sm font-weight-normal mb-0'>{'& above'}</p>
                                                        </Col>
                                                    ) : (
                                                        <Col style={{ maxWidth: 170 }} >
                                                            <p className='text-sm font-weight-normal mb-0'>{'& below, then less'}</p>
                                                        </Col>
                                                    )}
                                                    <Col style={{ maxWidth: 140 }}>
                                                        <InputGroup className='input-group-alternative'>
                                                            <Field 
                                                                className='form-control form-control-sm form-control-alternative'
                                                                type='number'
                                                                onWheel={(e) => e.target.blur()}
                                                                name={`sqftBetween[${i}].percent`}
                                                            />
                                                            <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                <InputGroupText className='bg-transparent' style={{ padding: '0rem 0.75rem' }}>
                                                                    <span>%</span>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col style={{ maxWidth: 140 }} className='d-flex justify-content-start align-items-center'>
                                                        <Button
                                                            color='secondary'
                                                            type='button'
                                                            size='sm'
                                                            onClick={() => remove(i)}
                                                        >
                                                            <div className='btn-inner--text text-red'>
                                                                <i 
                                                                    className='fa fa-minus'
                                                                />
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                        <Row>
                                            <Col md='12'>
                                                <a 
                                                    href='/' 
                                                    onClick={ (event) => {
                                                        event.preventDefault();
                                                        addSqftBetween(push);
                                                    }} 
                                                >
                                                    <span>
                                                        <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                        Add new line
                                                    </span>
                                                </a>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            />
                        ) : (
                            <>
                                <div className='mb-2'>
                                    <Row className='mb-2'>
                                        <Col style={{ maxWidth: 140 }}>
                                            <small className='font-weight-bold'>
                                                Quantity
                                            </small>
                                        </Col>
                                        <Col style={{ maxWidth: 150 }}>
                                        </Col>
                                        <Col style={{ maxWidth: 150 }}>
                                            <small className='font-weight-bold'>
                                                Costing
                                            </small>
                                        </Col>
                                        <Col style={{ maxWidth: 140 }}>
                                        </Col>
                                    </Row>
                                    {
                                        dynamicPriceBetween.length > 0? 
                                            dynamicPriceBetween.map((x, i) => {
                                                return (
                                                    <Row key={i} className='mb-2'>
                                                        <Col style={{ maxWidth: 140 }}>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Input
                                                                    className='pl-2'
                                                                    placeholder='Quantity'
                                                                    type='number'
                                                                    bsSize='sm'
                                                                    min='0'
                                                                    step='1'
                                                                    value={Number(x.quantity).toString()}
                                                                    onChange={(e) => lastTwoInput(e, i)}
                                                                    onBlur={(event) => performQuantityCheck(event, i)}
                                                                    onWheel={ event => event.currentTarget.blur() }
                                                                    disabled={(i === (dynamicPriceBetween.length - 1))}
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        {(i === (dynamicPriceBetween.length - 1)) ? (
                                                            <Col style={{ maxWidth: 150 }} className='text-sm font-weight-normal mb-0'><p className='text-sm font-weight-normal mb-0'>{'& above'}</p></Col>
                                                        ) : (
                                                            <Col style={{ maxWidth: 150 }} className='d-flex align-items-center'><p className='text-sm font-weight-normal mb-0'>{'& below, \t discount'}</p></Col>
                                                        )}
                                                        <Col style={{ maxWidth: 150 }}>
                                                            <InputGroup className='input-group-alternative'>
                                                                <Input
                                                                    placeholder='Percent Charge(%)'
                                                                    type='number'
                                                                    bsSize='sm'
                                                                    min='0.00'
                                                                    step='0.001'
                                                                    max='100.00'
                                                                    value={x.percent}
                                                                    onChange={(e) => dynamicPriceBetweenOnChange(Number(e.target.value), 'percent', i)}
                                                                    onWheel={ event => event.currentTarget.blur() }
                                                                />
                                                                <InputGroupAddon addonType='append' className='bg-secondary'>
                                                                    <InputGroupText className='bg-transparent' style={{ padding: '0rem 0.75rem' }}>
                                                                        <span>%</span>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col style={{ maxWidth: 140 }} className='d-flex justify-content-start align-items-center'>
                                                            <Button
                                                                color='secondary'
                                                                type='button'
                                                                size='sm'
                                                                onClick={() => removeDynamicPriceBetween(i)}
                                                            >
                                                                <div className='btn-inner--text text-red'>
                                                                    <i 
                                                                        className='fa fa-minus'
                                                                    />
                                                                </div>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                );
                                            }): null
                                    }
                                </div>
                                <Row>
                                    <Col md='12'>
                                        <a 
                                            href='/' 
                                            onClick={ (event) => {
                                                event.preventDefault();
                                                addCondition('quantity');
                                            }} 
                                        >
                                            <span>
                                                <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                Add new line
                                            </span>
                                        </a>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    
                   
                    
                </CardBody>
            </Card>
        </>
    );
};
 
export default DynamicPriceCalc;