import React from 'react';
import { Field } from 'formik';

import {
    Row,
} from 'reactstrap';
const CalculationType = ({
    width,
    background,
    title,
    hasSqft = true,
    hasSheets = true,
    hasAbsolute = true,
}) => {
    return (  
        <div style={{ width: width }}>
            <Row className='m-0 d-flex align-items-center'>
                <small className='mr-1 mb-1'>{title}</small>
            </Row>
            <Row className='m-0 px-3 d-flex align-items-center border rounded' style={{ background: background, justifyContent: 'space-evenly' }}>
                {(hasSheets) && (
                    <label className='d-flex align-items-center mb-0 mr-2'>
                        <Field style={{marginRight: '3px'}} type='radio' name='calcType' value='Sheet' default/>
                        <small className='mr-1 mb-0 w-10'>By Sheets</small>
                    </label>
                )}
                {(hasAbsolute) && (

                    <label className='d-flex align-items-center mb-0 mr-2'>
                        <Field style={{marginRight: '3px'}} type='radio' name='calcType' value='Absolute' />
                        <small className='mr-1 mb-0 w-10'>By Absolute</small>
                    </label>
                )}
                {(hasSqft) && (
                    <label className='d-flex align-items-center mb-0 mr-2'>
                        <Field style={{marginRight: '3px'}} type='radio' name='calcType' value='Sqft' />
                        <small className='mr-1 mb-0 w-10'>By Sqft</small>
                    </label>
                )}
            </Row>
        </div>
    );
};
 
export default CalculationType;
