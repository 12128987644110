import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';

import * as Yup from 'yup';
import Dropdown from '../Common/Dropdown.js';
import { withRouter } from 'react-router';
import env from '../../env.js';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';
import ComponentNumberInput from './ComponentNumberInput.js';
import DynamicPriceInput from './DynamicPriceInput.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';

// import component 👇
// import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    applicableSource: Yup.string().required('Source is required!'),
});

const BindingType = (props) => {
    const { isEdit, componentId, componentsTypeId, userId, dynamicPriceData, componentTypeName, onSubmit, duplicate } = props;
    const [supportedSource, setSupportedSource] = useState(env.componentSources);
    const [existingComponent, setExistingComponent] = useState();

    useEffect(() => {
        setSupportedSource(env.componentSources.filter((x) => { return (x.value === 'booklet'); }));
    }, []);

    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);

    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : undefined,
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    defaultSize: (existingComponent) ? existingComponent.defaultSize : 'mm',
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    data: (existingComponent) ? existingComponent.data : {
                        costing: {
                            basePrice: 0,
                            perBindingPrice: 0,
                        },
                    },
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;

                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Applicable for:</h5>
                                                        <Dropdown
                                                            name={'applicableSource'}
                                                            setFieldValue={setFieldValue}
                                                            disabled={(!!existingComponent)}
                                                            values={[
                                                                {
                                                                    value: 'booklet',
                                                                    display: 'SC-booklet'
                                                                }
                                                            ]}
                                                            width={'auto'}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.applicableSource && touched.applicableSource ? (
                                                            <small className='text-danger xs'>{errors.applicableSource}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-1 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                    {(values.data.costing.perSheetPrice || values.data.costing.basePrice) ? (
                                                        <DoneStepIcon className='mr-4'/>
                                                    ) : (
                                                        <div className='mr-4 d-flex align-items-center justify-content-center'
                                                            style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                            1
                                                        </div>
                                                    )}
                                                    <div>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <small className='mr-1 mb-1'>Costing* </small>
                                                        </Row>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <Col md='5' className='p-0'>
                                                                <ComponentNumberInput
                                                                    type={'number'}
                                                                    className='mr-2'
                                                                    title={'Base Price'}
                                                                    location={'data.costing.basePrice'}
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                
                                                            </Col>
                                                            <Col md='5' className='p-0'>
                                                                <ComponentNumberInput
                                                                    type={'number'}
                                                                    title={'Per Binding'}
                                                                    location={'data.costing.perSheetPrice'}
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                            </Col>
                                                        </Row>    
                                                    </div>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                    {(values.dynamicPriceId) ? (
                                                        <DoneStepIcon className='mr-4'/>
                                                    ) : (
                                                        <div className='mr-4 d-flex align-items-center justify-content-center'
                                                            style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                            2
                                                        </div>
                                                    )}
                                                    <div>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <small className='mr-1 mb-1'>Dynamic Price (optional) </small>
                                                        </Row>
                                                        <DynamicPriceInput
                                                            setFieldValue={setFieldValue}
                                                            dynamicPriceData={dynamicPriceData.filter((x) => x.type !== 'sqft')}
                                                            location={'dynamicPriceId'}
                                                        />
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(BindingType);