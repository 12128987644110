import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Yup from 'yup';
import Dropdown from '../Common/Dropdown.js';
import DefaultSize from './DefaultSize.js';
import {ReactComponent as DeleteIcon} from '../../assets/img/icons/common/delete.svg';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';

import ComponentNumberInput from './ComponentNumberInput.js';
import ComponentTextInput from './ComponentTextInput.js';

import { withRouter } from 'react-router';
import env from '../../env.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody,
    InputGroup,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    applicableSource: Yup.string().required('Source is required!'),
});

const PrintingSize = (props) => {
    const { isEdit, componentId, componentsTypeId, userId, componentTypeName, onSubmit, duplicate } = props;
    const [supportedSource, setSupportedSource] = useState(env.componentSources);
    const [existingComponent, setExistingComponent] = useState();

    useEffect(() => {
        setSupportedSource(env.componentSources.filter((x) => { return (x.value !== 'csv' && x.value !== 'api'); }));
    }, []);

    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);

    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : undefined,
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    defaultSize: (existingComponent) ? existingComponent.defaultSize : 'mm',
                    componentsTypeId,
                    componentTypeName,
                    customSizePrice: (existingComponent) ? existingComponent.customSizePrice : 0,
                    status: (existingComponent) ? existingComponent.status : 1,
                    data: (existingComponent) ? existingComponent.data : {
                        printingSizeDetails: {
                            bleedTop: 0,
                            bleedBottom: 0,
                            bleedLeft: 0,
                            bleedRight: 0,
                            minWidth: 0,
                            minHeight: 0,
                            hasCustomSize: false,
                            customSizePrice: 0,
                            customEnableWastage: false,
                            sizes: [{
                                label: 'A7 (74mm x 105mm)',
                                width: 74,
                                height: 105,
                                basePrice: 0,
                            },{
                                label: 'A6 (105mm x 148mm)',
                                width: 105,
                                height: 148.5,
                                basePrice: 0,
                            },{
                                label: 'A5 (148mm x 210mm)',
                                width: 148.5,
                                height: 210,
                                basePrice: 0,
                            },{
                                label: 'A4 (210mm x 297mm)',
                                width: 210,
                                height: 297,
                                basePrice: 0,
                            },{
                                label: 'A3 (297mm x 420mm)',
                                width: 297,
                                height: 420,
                                basePrice: 0,
                            },{
                                label: 'DL (99mm x 210mm)',
                                width: 99,
                                height: 210,
                                basePrice: 0,
                            }]
                        },
                    }
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-4'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-2 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Applicable for:</h5>
                                                        <Dropdown
                                                            name={'applicableSource'}
                                                            setFieldValue={setFieldValue}
                                                            disabled={(existingComponent)}
                                                            values={[
                                                                ...supportedSource
                                                            ]}
                                                            width={'100%'}
                                                        />
                                                        {errors.applicableSource && touched.applicableSource ? (
                                                            <small className='text-danger xs'>{errors.applicableSource}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.defaultSize ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                1
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <DefaultSize
                                                            width={270}
                                                            background={'white'}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {(values.data.printingSizeDetails.sizes.length > 0) ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                2
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <Col md='2' className='p-0 pb-1'>
                                                                <small className='mr-5 mb-1'>Print Size Details* </small>
                                                            </Col>
                                                            {values?.applicableSource?.includes('poster') && (
                                                                <Col md='3'>
                                                                    <small className='ml-5 mb-1'>Enable Wastage</small>
                                                                </Col>
                                                            )}
                                                            {values?.applicableSource?.includes('booklet') && (
                                                                <Col md='7' className='ml-4 d-flex justify-content-center'>
                                                                    <small className='ml-5 mb-1'>Open Size*</small>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                        <FieldArray
                                                            className='mb-3'
                                                            name='data.printingSizeDetails.sizes'
                                                            render={({ push, remove, move }) => (
                                                                <>
                                                                    <Col className='mb-3 p-0'>
                                                                        <DragDropContext onDragEnd={(result) => {
                                                                            if (!result.destination) {
                                                                                return;
                                                                            }
                        
                                                                            move(result.source.index, result.destination.index);
                                                                        }}>

                                                                            <Droppable droppableId='printingSizeDetails.sizes'>
                                                                                {(provided) => (
                                                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                        {values.data.printingSizeDetails.sizes.map((sizeDetail, index) => (
                                                                                            <Draggable key={index} draggableId={`sizeDetail-${index}`} index={index}>
                                                                                                {(provided) => (
                                                                                                    <div
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                    >
                                                                                                        <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                                                            <Col md='3' className='pr-0'>
                                                                                                                <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                                                    <ComponentTextInput
                                                                                                                        hasDrag
                                                                                                                        placeholder={'Label'}
                                                                                                                        location={`data.printingSizeDetails.sizes[${index}].label`}
                                                                                                                    />
                                                                                                                </Row>
                                                                                                            </Col>
                                                                                                        
                                                                                                            {values?.applicableSource?.includes('poster') && (
                                                                                                                <div style={{ width: '3%'}}>
                                                                                                                    <Field 
                                                                                                                        type='checkbox'
                                                                                                                        name={`data.printingSizeDetails.sizes[${index}].enableWastage`}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            )}

                                                                                                            <Col md='3' className='p-0'>
                                                                                                                <ComponentNumberInput
                                                                                                                    type={'number'}
                                                                                                                    className='mr-2'
                                                                                                                    title={'Max Width'}
                                                                                                                    location={`data.printingSizeDetails.sizes[${index}].width`}
                                                                                                                    chipText={values.defaultSize}
                                                                                                                />
                                                                                                            </Col>

                                                                                                            <Col md='3' className='p-0'>
                                                                                                                <ComponentNumberInput
                                                                                                                    type={'number'}
                                                                                                                    className='mr-2'
                                                                                                                    title={'Max Height'}
                                                                                                                    location={`data.printingSizeDetails.sizes[${index}].height`}
                                                                                                                    chipText={values.defaultSize}
                                                                                                                />
                                                                                                            </Col>
                                                                           
                                                                                                            <Col className='p-0'>
                                                                                                                <ComponentNumberInput
                                                                                                                    type={'number'}
                                                                                                                    className='mr-2'
                                                                                                                    title={'Base Price'}
                                                                                                                    location={`data.printingSizeDetails.sizes[${index}].basePrice`}
                                                                                                                />
                                                                                                            </Col>

                                                                                                            <Col md='1'>
                                                                                                                <Button 
                                                                                                                    color='danger'
                                                                                                                    className='btn-round'
                                                                                                                    onClick={() => remove(index)}
                                                                                                                >
                                                                                                                    <DeleteIcon 
                                                                                                                        width={14}
                                                                                                                        height={14}
                                                                                                                    />
                                                                                                                </Button>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        ))}
                                                                                        <Row className='d-flex align-items-start mb-3' style={{ paddingLeft: 15}}>
                                                                                            <a 
                                                                                                href='/' 
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    push({ label: '', width: 0, height: 0, basePrice: 0 });
                                                                                                }}      
                                                                                            >
                                                                                                <span>
                                                                                                    <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                                                                    Add Size
                                                                                                </span>
                                                                                            </a>
                                                                                        </Row>
                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                )}
                                                                            </Droppable>
                                                                        </DragDropContext>
                                                                    </Col>
                                                                </>
                                                            )}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.data.printingSizeDetails.hasCustomSize ? (
                                                            <DoneStepIcon/>
                                                        ) : (
                                                            <div className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                3
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col md='11'>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <Col md='3' className='p-0 d-flex align-items-center pb-1'>
                                                                <small className='mr-3 mb-0'>Custom Size (optional)</small>
                                                                <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                                                    <input 
                                                                        type='checkbox' 
                                                                        checked={values.data.printingSizeDetails.hasCustomSize}
                                                                        onClick={() => setFieldValue('data.printingSizeDetails.hasCustomSize', !values.data.printingSizeDetails.hasCustomSize)}
                                                                    />
                                                                    <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                                                </label>
                                                            </Col>
                                                            {values?.applicableSource?.includes('poster') && (values.data.printingSizeDetails.hasCustomSize) && (
                                                                <Col md='3' className='p-0 d-flex align-items-center'>
                                                                    <small className='ml-0 mb-1'>Enable Wastage</small>
                                                                </Col>
                                                            )}
                                                            
                                                        </Row>
                                                        {(values.data.printingSizeDetails.hasCustomSize) && (
                                                            <Row key={'custom'} className='m-0 align-items-center'>
                                                                <Col md='3' className='pr-0 mr-3'>
                                                                    <Row className={'m-0 d-flex align-items-center justify-content-between rounded bg-white w-100'}>
                                                                        <div className='input-card d-flex align-items-center w-100'>
                                                                            <InputGroup className='input-group-alternative d-flex align-items-center p-1 border-0 w-100' style={{ borderRadius: 50, marginRight: 5, background: '#EBF5FF' }}>
                                                                                <Field 
                                                                                    className='form-control form-control-sm form-control-alternative mr-1 bg-transparent'
                                                                                    style={{ borderRadius: 50 }}
                                                                                    type={'text'}
                                                                                    value={'Custom'}
                                                                                />
                                                                            </InputGroup>
                                                                        </div>
                                                                    </Row>
                                                                </Col>

                                                                {values?.applicableSource?.includes('poster') && (
                                                                    <div style={{ width: '3%'}}>
                                                                        <Field 
                                                                            type='checkbox'
                                                                            name={'data.printingSizeDetails.customEnableWastage'}
                                                                        />
                                                                    </div>
                                                                )}
                                                                                            
                                                                <Col md='3' className='p-0'>
                                                                    <ComponentNumberInput
                                                                        type={'number'}
                                                                        className='mr-2'
                                                                        title={'Min Width'}
                                                                        location={'data.printingSizeDetails.minWidth'}
                                                                        chipText={values.defaultSize}
                                                                    />
                                                                </Col>

                                                                <Col md='3' className='p-0'>
                                                                    <ComponentNumberInput
                                                                        type={'number'}
                                                                        className='mr-2'
                                                                        title={'Min Height'}
                                                                        location={'data.printingSizeDetails.minHeight'}
                                                                        chipText={values.defaultSize}
                                                                    />
                                                                </Col>
                                                                <Col md='2' className='p-0'>
                                                                    <ComponentNumberInput
                                                                        type={'number'}
                                                                        className='mr-2'
                                                                        title={'Base Price'}
                                                                        location={'data.printingSizeDetails.customSizePrice'}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                </Row>

                                                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                                                    <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                                        {values.data.printingSizeDetails.bleedTop
                                                        || values.data.printingSizeDetails.bleedBottom
                                                        || values.data.printingSizeDetails.bleedLeft
                                                        || values.data.printingSizeDetails.bleedRight
                                                            ? (
                                                                <DoneStepIcon/>
                                                            ) : (
                                                                <div className='d-flex align-items-center justify-content-center'
                                                                    style={{ width: 32, height: 32, background: 'white', borderRadius: '50%', border: '1px solid #166BAE', color: '#166BAE' }}>
                                                                    4
                                                                </div>
                                                            )}
                                                    </Col>
                                                    <Col md='8'>
                                                        <Row className='m-0 d-flex align-items-center'>
                                                            <small className='mr-3 mb-0 pb-1'>Bleed Area (optional)</small>
                                                        </Row>
                                                        <Row className='m-0 align-items-center justify-content-between flex-nowrap'>
                                                            <ComponentNumberInput
                                                                type={'number'}
                                                                className='mr-2'
                                                                title={'Top'}
                                                                location={'data.printingSizeDetails.bleedTop'}
                                                                chipText={values.defaultSize}
                                                            />
                                                            <ComponentNumberInput
                                                                type={'number'}
                                                                className='mr-2'
                                                                title={'Bottom'}
                                                                location={'data.printingSizeDetails.bleedBottom'}
                                                                chipText={values.defaultSize}
                                                            />
                                                            <ComponentNumberInput
                                                                type={'number'}
                                                                className='mr-2'
                                                                title={'Left'}
                                                                location={'data.printingSizeDetails.bleedLeft'}
                                                                chipText={values.defaultSize}
                                                            />
                                                            <ComponentNumberInput
                                                                type={'number'}
                                                                className='mr-2'
                                                                title={'Right'}
                                                                location={'data.printingSizeDetails.bleedRight'}
                                                                chipText={values.defaultSize}
                                                            />
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(PrintingSize);