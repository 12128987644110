import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import ObjectId from 'bson-objectid';
import { withRouter } from 'react-router';
import { useComponents } from '../../../zustand/componentStore';
import { ReactComponent as SearchIcon } from '../../../assets/img/icons/common/search.svg';

import {
    Row,
    Col,
    Modal,
    Input,
    InputGroup,
    Button,
    Card,
    CardBody
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import client from '../../../feathers';
import { uniqBy } from 'lodash';

const ComponentSelector = (props) => {
    const {
        userId,
        values,
        applicableSource,
        setFieldValue,
        notificationOpen,
    } = props;

    const [isHover, setIsHover] = useState(false);
    const [hoverCode, setHoverCode] = useState('');
    const [hoverName, setHoverName] = useState('');
    const [hoverDescription, setHoverDescription] = useState('');

    const {
        addComponentModalIsOpen: isOpen,
        excludedComponentTypes,
        modalAllowBack,
        selectedComponentTypeId,
        selectedComponentType,
        storedLocation,
        actions,
    } = useComponents();

    const {
        setAddComponentModalIsOpen: toggle,
        setSelectedComponentTypeId
    } = actions;

    const [searchQuery, setSearchQuery] = useState('');
    const [searchComponentTypeQuery, setSearchComponentTypeQuery] = useState('');
    const [filter, setFilter] = useState(-1);
    const [components, setComponents] = useState([]);
    const [componentTypes, setComponentTypes] = useState([]);

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    return client.service('components').find({
                        query: {
                            userId,
                            componentsTypeId: (selectedComponentTypeId) ? selectedComponentTypeId : undefined,
                            orRegex: { name: searchQuery },
                            $or: [
                                {
                                    applicableSource: applicableSource
                                },
                                {
                                    allowAllSource: true,
                                }
                            ],
                            $sort: { createdAt: filter },
                            $limit: 1000,
                        }
                    });
                })
                .then((res) => {
                    setComponents(res.data);
                });
        }
    }, [selectedComponentTypeId, searchQuery, filter]);

    useEffect(() => {
        if(userId) {
            client.authenticate()
                .then(()=>{
                    let componentTypeQuery = { name: { $nin: excludedComponentTypes } };

                    if (applicableSource.includes('csv')) {
                        componentTypeQuery = { name: { $in: ['Production Timeline', 'Artwork Service', 'File Storage', 'Quantity'] } };
                    }
                    return client.service('componentsType').find({
                        query: {
                            $limit: 1000,
                            ...componentTypeQuery,
                            $or: [{ userId: new ObjectId(userId) }, { userId: null }],
                            $sort: { createdAt: 1 },
                        }
                    });
                })
                .then((res) => {
                    setComponentTypes(res.data);
                });
        }
    }, [selectedComponentTypeId, excludedComponentTypes]);

    const addComponent = (componentType, value) => {
        if(componentType === 'Printing Size' ||
                (componentType === 'Printing Cost' && !applicableSource.includes('poster')) ||
                componentType === 'Binding Type' ||
                componentType === 'Quantity' ||
                componentType === 'Production Timeline' ||
                componentType === 'Artwork Service'
        ) {
            // for single item
            const item = [{
                componentId: value._id,
                componentType,
                ...value,
                nestedComponents: [],
            }];
            setFieldValue(storedLocation, item);
            toggle(false);
        } else {
            // for multiple items
            const cloned = cloneDeep(eval('values.' + storedLocation));
            cloned.push({
                componentId: value._id,
                componentType,
                ...value,
                nestedComponents: [],
            });

            const unique = uniqBy(cloned, 'componentId');
            setFieldValue(storedLocation, unique);
            toggle(false);
        }
    };

    if (!selectedComponentTypeId) {
        return (
            <Modal
                className='modal-dialog-top'
                isOpen={isOpen}
                toggle={() => toggle(!isOpen)}
            >
                <div className='pt-3 pb-0 modal-header d-flex justify-content-end align-items-center'>
                    <button
                        aria-label='Close'
                        className='modal-close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggle(!isOpen)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body py-2'>
                    <div className='mb-2'>
                        <InputGroup className='input-group-alternative d-flex align-items-center'>
                            <SearchIcon height={20} className='mx-1'/>
                            <Input
                                placeholder='Search Component Name...'
                                className='w-auto d-inline-block mr-2'
                                type='text'
                                bsSize='sm'
                                size={20}
                                value={searchComponentTypeQuery}
                                onChange={(e) => setSearchComponentTypeQuery(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    
                    {componentTypes.length > 0 && (
                        <div className='w-100 bg-light-grey rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                            {componentTypes.map((x) => {
                                return (
                                    <Row
                                        key={x._id}
                                        className='d-flex py-2 mx-1 component-selector-item rounded align-items-center'
                                        onClick={() => [
                                            setSelectedComponentTypeId(x._id),
                                        ]}
                                    >
                                        <Col md={12}>
                                            <h4 className='m-0'>
                                                {x.name}
                                            </h4>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    )}
                </div>
            </Modal>
        );
    } else {
        return (
            <Modal
                className='modal-dialog-top'
                isOpen={isOpen}
                toggle={() => toggle(!isOpen)}
            >
                <div className='pt-3 pb-0 modal-header d-flex justify-content-between align-items-center'>
                    {(modalAllowBack) && (
                        <button
                            style={{ fontSize: 18 }}
                            aria-label='Back'
                            className='modal-back'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => setSelectedComponentTypeId('')}
                        >
                            <span aria-hidden={true}>
                                <i className='fas fa-angle-left'/>
                            </span>
                        </button>
                    )}
                    <div></div>
                    <button
                        aria-label='Close'
                        className='modal-close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => toggle(!isOpen)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body py-2'>
                    <div className='mb-2'>
                        <InputGroup className='input-group-alternative d-flex align-items-center'>
                            <SearchIcon height={20} className='mx-1'/>
                            <Input
                                placeholder='Search Component Name...'
                                className='w-auto d-inline-block mr-2'
                                type='text'
                                bsSize='sm'
                                size={20}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <div className='mb-2'>
                        <Button
                            className={`shadow-none rounded bg-grey p-1 px-2 border-0 ${(filter === -1) ? 'active' : ''}`}
                            onClick={() => setFilter(-1)}
                        >
                            Most Recent
                        </Button>
                        <Button
                            className={`shadow-none rounded bg-grey p-1 px-2 border-0 ${(filter === 1) ? 'active' : ''}`}
                            onClick={() => setFilter(1)}
                        >
                            View All
                        </Button>
                    </div>
                    {(isHover) && (
                        <Card className='bg-yellow rounded position-absolute' style={{ width: 300, minHeight: 100, zIndex: 10, left: 510, top: 0}}>
                            <CardBody className='p-3'>
                                <Row className='p-0 m-0 mb-2'>
                                    <small>{hoverCode} | {hoverName}</small>
                                </Row>
                                <Row className='p-0 m-0'>
                                    <h5 className='m-0'>Description:</h5>
                                </Row>
                                <Row className='p-0 m-0'>
                                    <small>{(hoverDescription) ? hoverDescription : '-'}</small>
                                </Row>
                            </CardBody>
                            <div className='position-absolute'
                                style={{
                                    left: '-10px',
                                    bottom: '5%',
                                    width:0,
                                    borderRight:'10px solid #FFCA3A',
                                    borderTop:'7px solid transparent',
                                    borderBottom:'7px solid transparent',
                                }} />
                        </Card>
                    )}
                    {components.length > 0 ? (
                        <div className='w-100 bg-grey rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                            {components.map((x) => {
                                return (
                                    <Row
                                        key={x._id}
                                        className='d-flex py-2 mx-1 component-selector-item rounded align-items-center position-relative'
                                        onClick={() => addComponent(selectedComponentType, x)}
                                    >
                                        <Col md={2} className='component-code m-0 text-uppercase'>
                                            {x.code}
                                        </Col>
                                        <Col md={10}>
                                            <h4 className='m-0' 
                                                onMouseOver={() => [
                                                    setIsHover(true),
                                                    setHoverCode(x.code),
                                                    setHoverName(x.name),
                                                    setHoverDescription(x.description),
                                                ]}
                                                onMouseOut={() => [
                                                    setIsHover(false),
                                                    setHoverCode(''),
                                                    setHoverName(''),
                                                    setHoverDescription(''),
                                                ]}>
                                                {x.name}
                                            </h4>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    ) : (
                        <div className='w-100 d-flex justify-content-center rounded p-2' style={{ maxHeight: 400, overflowY: 'scroll' }}>
                            No supported component.
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

export default withRouter(ComponentSelector);
